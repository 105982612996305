import Formularios from './views/Formulario/Formularios.js';
import ResponderFormulario from './views/Formulario/ResponderFormulario.js'; 
import ResultadoFormulario from './views/Formulario/ResultadoFormulario.js';  
import ResultadoFormularioDet from './views/Formulario/ResultadoFormularioDet.js'; 
import PocketMarginResults from './views/PocketMargin/PocketMarginResults.js'; 
import SummaryReportsCharts from './views/SummaryReportsCharts/SummaryReportsCharts';
import DailyOperationDashboard from './views/ProductionsDashboards/DailyOperationDashboard/DailyOperationDashboard.jsx';
import OperationDowntimesParetos from './views/ProductionsDashboards/OperationDownTimes/OperationDowntimesParetos.jsx';
import OperationScrapDashboard from './views/ProductionsDashboards/OperationScrap/OperationScrapDashboard.jsx';
import AnalisisCostos from './views/AnalisisCostos/AnalisisCostos.jsx';
import ProductionsDashboards from './views/ProductionsDashboards/ProductionsDashboards.jsx';
import ActiveDowntimes from './views/ProductionsDashboards/ActiveDowntimes/ActiveDowntimes.jsx';
import PerformanceDevSetUp from './views/HR/PerformanceDevSetUp/PerformanceDevSetUp.jsx';
import Home from './views/Home';
import HRHome from './views/HR/Home/HRHome.jsx';
import PerformanceDevelpmentHome from './views/HR/PerformanceDevelopment/PerformanceDevelpmentHome.jsx';
import Survey180DegreeScreen from './views/HR/CompanyCulture/180DegreeFeedback/Survey180DegreeScreen.jsx'
import AnnualReview from './views/HR/PerformanceDevelopment/AnnualReview/AnnualReviewScreen.jsx';
import IndividualDevelopment from './views/HR/IndividualDevelopment/IndividualDevelopmentHome.jsx';
import MyActivities from './views/ScoreCard/MyActivities/MyActivities.jsx';
import EditarProyecto from './views/ScoreCard/Projects/EditarProyecto.jsx';
import SolicitudMaterialCeldaScreen from 'views/Operations/Tablets/SolicitudMaterial/SolicitudMaterialCeldaScreen';
import SolicitudMaterialListScreen from 'views/Operations/Tablets/SolicitudMaterial/SolicitudMaterialListScreen';
import SolicitudMaterialDashboardScreen from 'views/Operations/Tablets/SolicitudMaterial/SolicitudMaterialDashboardScreen.jsx';
import MisProyectosScreen from './views/ScoreCard/MyProjects/MisProyectosScreen.jsx';
import PagerOnBreakScreen from 'views/Operations/Tablets/Pagers/PagerOnBreakScreen.jsx';
import EnterTicketScreen from './views/IT/Tickets/EnterTicketScreen.jsx';
import ManageITTicketsScreen from './views/IT/Tickets/ManageITTicketsScreen.jsx';
import ViewITTicketsScreen from './views/IT/Tickets/ViewITTicketsScreen.jsx';
import SpreadPrice from './views/CorporateReports/SpreadPrice/SpreadPriceScreen.jsx';
import FocusReportScreen from './views/ScoreCard/Scorecard/FocusReport/FocusReportScreen.jsx';
import PuestoIndicadorScreen from './views/ScoreCard/Scorecard/PuestoIndicadorScreen.jsx';
import PLAdministrationScreen from './views/CorporateReports/PLAdministration/PLAdministrationScreen.jsx';
import MeetingManagementScreen from './views/ScoreCard/Projects/MeetingManagementScreen.jsx';
import SurveyManagementScreen from './views/HR/Survey/SurveyManagement/SurveyManagementScreen.jsx';
import ToolsPartsMappingScreen from './views/Operations/ToolRoom/ToolsPartsMappingScreen.jsx';
import PLByBucketScreen from './views/CorporateReports/PLByBucket/PLByBucketScreen.jsx';
import SurveyAnalyzeScreen from './views/HR/Survey/SurveyAnalyze/SurveyAnalyzeScreen.jsx';
import TableauDashboardScreen from 'views/ProductionsDashboards/TableauDashboard/TableauDashboardScreen.jsx';
import OrganizationalScoreSceen from 'views/ScoreCard/Scorecard/OrganizationalScore/OrganizationalScoreSceen.jsx';
import SalesPerformanceDashboardScreen from 'views/SalesPerformance/SalesPerformanceDashboardScreen.jsx';
import MonthlyFollowUpDashboardScreen from 'views/SalesPerformance/IncentiveFollowUp/MonthlyProgress/MonthlyFollowUpDashboardScreen.jsx';
import YTDProgressDashboardScreen from 'views/SalesPerformance/IncentiveFollowUp/YTDProgress/YTDProgressDashboardScreen.jsx';
import SalesPerformanceSalesRepManagementScreen from 'views/SalesPerformance/Settings/SalesPerformanceSalesRepManagementScreen.jsx';
import IncentiveProgramSetUpScreen from 'views/SalesPerformance/InsentiveProgramSetup/IncentiveProgramSetUpScreen.jsx';
import IncentiveProgramsGoalsScreen from 'views/SalesPerformance/IncentiveProgramsGoals/IncentiveProgramsGoalsScreen.jsx';
import PODScreen from 'views/Operations/POD/PODScreen.jsx';
import FolderScreen from 'views/DMS/Folder/FolderScreen.jsx';
import LibraryScreen from 'views/DMS/Library/LibraryScreen.jsx';
import FileLibraryScreen from 'views/DMS/Library/FileLibraryScreen.jsx';
import FileLibraryByUserScreen from 'views/DMS/Library/FileLibraryByUserScreen.jsx';
import IndividualIncetiveCompensationScreen from 'views/SalesPerformance/IndividualIncetiveCompensation/IndividualIncetiveCompensationScreen.jsx';
import DownTimeScreen from 'views/Operations/Tablets/DownTime/DownTimeScreen.jsx';
import VisitLogScreen from 'views/HR/Survey/VisitLog/VisitLogScreen.jsx';
import VisitLogMovilScreen from 'views/HR/Survey/VisitLog/VisitLogMovilScreen.jsx';
import VisitLog from 'views/HR/Survey/VisitLog/VisitLog.jsx';
import CheckInScreen from 'views/Operations/Tablets/CheckIn/CheckInScreen.jsx';
import CellHomeMenuScreen from 'views/Operations/Tablets/HomeMenu/CellHomeMenuScreen.jsx';
import DailyOperationCheckListScreen from 'views/Operations/Tablets/DailyOperationCheckList/DailyOperationCheckListScreen.jsx';
import ScrapScreen from 'views/Operations/Tablets/Common/Scrap/ScrapScreen.jsx';
import CheckOutScreen from 'views/Operations/Tablets/CheckIn/CheckOutScreen.jsx';
import ChangeOverScreen from 'views/Operations/Tablets/CheckIn/ChangeOverScreen.jsx';
import ExecutiveOrderTrendScreen from 'views/SalesPerformance/ExecutiveOrderTrend/ExecutiveOrderTrendScreen.jsx';
import DisplayOperationsDashboards from 'views/ProductionsDashboards/DisplayOperationsDashboards/DisplayOperationsDashboards.jsx';
import PlanningScreen from './views/Operations/Warehouse/Shipments/Planning/PlanningScreen.jsx'
import DashboardUtilizationScreen from 'views/Operations/Tablets/Dashboard/DashboardUtilization/DashboardUtilizationScreen.jsx';
import DashboardScrapScreen from 'views/Operations/Tablets/Dashboard/DashboardScrap/DashboardScrapScreen.jsx';
import BMTrackingDashboardsScreen from 'views/SalesPerformance/BMTrackingDashboards/BMTrackingDashboardsScreen.jsx';
import TalentPlanningScreen from 'views/HR/TalentPlanning/TalentPlanningScreen.jsx';
import BusinessCard from 'views/HR/DigitalBusinessCards/BusinessCard.jsx';
import ManageBusinessCardsScreen from 'views/HR/DigitalBusinessCards/ManageBusinessCardsScreen.jsx';
import OrangePageScreen from 'views/ScoreCard/Projects/OrangePageScreen.jsx';
import SalesDashboardScreen from 'views/SalesPerformance/SalesDashboard/SalesDashboardScreen.jsx';
import WireSteelGeneralViewScreen from 'views/SalesPerformance/WireSteelGeneralView/WireSteelGeneralViewScreen.jsx';
import MMADashboardScreen from 'views/Operations/MMA/MMADashboardScreen.jsx';
import DigitalViewDashboardScreen from 'views/Operations/DGT/DigitalViewDashboardScreen.jsx';
import ITAssetsScreen from './views/IT/ITAssets/ITAssetsScreen.jsx';
import MTDPerformanceReportScreen from 'views/Operations/MTD/MTDPerformanceReportScreen.jsx';
import UsageTrackingScreen from 'views/IT/Tracking/UsageTrackingScreen.jsx';
import BacklogByDivisionScreen from 'views/SalesPerformance/BacklogByDivision/BacklogByDivisionScreen.jsx';
import PriceTrackerScreen from 'views/SalesPerformance/PriceTracker/PriceTrackerScreen.jsx';
import IncentiveProgramManualAdjustmentsScreen from 'views/SalesPerformance/IncentiveProgramManualAdjustments/IncentiveProgramManualAdjustmentsScreen.jsx';
import SalesDataPivotScreen from 'views/SalesPerformance/SalesDataPivot/SalesDataPivotScreen.jsx';
import ProductionDailyFollowUPScreen from 'views/Operations/WTW/ProductionDailyFollowUPScreen.jsx';
import VolumeArchitectureOverviewScreen from 'views/SalesPerformance/VolumeArchitectureOverview/VolumeArchitectureOverviewScreen.jsx';
import ProductionWeeklyFollowUPScreen from 'views/Operations/WTW/ProductionWeeklyFollowUPScreen.jsx';
import DAISeerScreen from 'views/DAISeer/DAISeerScreen/DAISeerScreen.jsx';
import SteelTrackMonitorScreen from 'views/SalesPerformance/SteelTrackMonitor/SteelTrackMonitorScreen.jsx';
import CompanyOverviewScreen from 'views/Operations/CompanyOverview/CompanyOverviewScreen.jsx';
import IndividualDevelopmentPlanScreen from './views/HR/IndividualDevelopment/IndividualDevelopmentPlanScreen.jsx';
import BusinessEnablingServices2024 from './views/IT/BusinessEnablingServices/BusinessEnablingServices2024.jsx';
import ProfitCubeScreen from 'views/SalesPerformance/ProfitCube/ProfitCubeScreen.jsx';
import ConstructConnectScreen from 'views/SalesPerformance/ConstructConnect/ConstructConnectScreen.jsx';
import GantryBoxLookupScreen from 'views/Operations/GantryBoxLookup/GantryBoxLookupScreen.jsx';
import CustomerServiceScreen from 'views/SalesPerformance/CustomerService/CustomerServiceScreen.jsx';
import DailyOperationTrackerScreen from 'views/Operations/WTW/DailyOperationTracker/DailyOperationTrackerScreen.jsx';
import MCSWNewsletterScreen from 'views/HR/Newsletter/MCSWNewsletterScreen.jsx';
import DnTNewsletterScreen from 'views/IT/DnTNewsletter/DnTNewsletterScreen.jsx';
import InventoryFollowUpScreen from 'views/SalesPerformance/CranePointFollowUP/InventoryFollowUpScreen.jsx';
import WeeklySalesScreen from 'views/SalesPerformance/CranePointFollowUP/WeeklySalesScreen.jsx';
import MonthlySalesScreen from 'views/SalesPerformance/CranePointFollowUP/MonthlySalesScreen.jsx';
import ManageADPEmployeesScreen from 'views/HR/EmployeeManagement/ManageADPEmployeesScreen.jsx';
import SalesRepFollowUpScreen from 'views/SalesPerformance/CranePointFollowUP/SalesRepFollowUpScreen.jsx';
import SalesByLocationScreen from 'views/SalesPerformance/CranePointFollowUP/SalesByLocationScreen.jsx';
import ViewEmployeeItAssetsScreen from 'views/HR/EmployeeManagement/ViewEmployeeITAssetsScreen.jsx';
import DailyProductionSummaryScreen from 'views/ProductionsDashboards/DailyProductionSummary/DailyProductionSummaryScreen.jsx';
import DailyDowntimeSummaryScreen from 'views/ProductionsDashboards/DailyDowntimeSummary/DailyDowntimeSummaryScreen.jsx';
import MonthlyProductionSummaryScreen from 'views/ProductionsDashboards/MonthlyProductionSummary/MonthlyProductionSummaryScreen.jsx';
import InventoryWeeklyFollowUpScreen from 'views/SalesPerformance/CranePointFollowUP/InventoryWeeklyFollowUpScreen.jsx';
import MonthlyDowntimeSummaryScreen from 'views/ProductionsDashboards/MonthlyDowntimeSummary/MonthlyDowntimeSummaryScreen.jsx';
import ProductionMonthlyFollowUPScreen from 'views/Operations/WTW/ProductionMonthlyFollowUP/ProductionMonthlyFollowUPScreen.jsx';
import InventoryBySGDashScreen from 'views/SalesPerformance/CranePointFollowUP/InventoryBySGDashScreen.jsx';
import ARAgingDashScreen from 'views/SalesPerformance/CranePointFollowUP/ARAgingDashScreen.jsx';
import APAgingDashScreen from 'views/SalesPerformance/CranePointFollowUP/APAgingDashScreen.jsx';
import UploadResultsCompensationScreen from 'views/SalesPerformance/UploadResultsCompensation/UploadResultsCompensationScreen.jsx';

const Components = {
    "Formularios": Formularios,
    "ResponderFormulario": ResponderFormulario,
    "PocketMarginResults": PocketMarginResults,
    "ResultadoFormulario": ResultadoFormulario ,
    "ResultadoFormularioDet" : ResultadoFormularioDet,
    "SummaryReportsCharts" : SummaryReportsCharts ,
    "DailyOperationDashboard" : DailyOperationDashboard ,
    "OperationDowntimesParetos" : OperationDowntimesParetos ,
    "OperationScrapDashboard" : OperationScrapDashboard,
    "AnalisisCostos":AnalisisCostos,
    "ProductionsDashboards":ProductionsDashboards,
    "ActiveDowntimes":ActiveDowntimes,
    "Home":Home,
    "PerformanceDevSetUp":PerformanceDevSetUp,
    "HRHome":HRHome,
    "PerformanceDevelpmentHome":PerformanceDevelpmentHome,
    "Survey180DegreeScreen" : Survey180DegreeScreen,
    "AnnualReview" : AnnualReview,
    "IndividualDevelopment":IndividualDevelopment,
    "MyActivities":MyActivities,
    "EditarProyecto" : EditarProyecto,
    "SolicitudMaterialCeldaScreen" : SolicitudMaterialCeldaScreen,
    "SolicitudMaterialListScreen" : SolicitudMaterialListScreen,
    "SolicitudMaterialDashboardScreen" : SolicitudMaterialDashboardScreen,
    "MisProyectosScreen":MisProyectosScreen,
    "PagerOnBreakScreen" : PagerOnBreakScreen,
    "EnterTicketScreen" : EnterTicketScreen,
    "ManageITTicketsScreen": ManageITTicketsScreen,
    "ViewITTicketsScreen": ViewITTicketsScreen,
    "SpreadPrice": SpreadPrice,
    "FocusReportScreen" : FocusReportScreen,
    "PuestoIndicadorScreen":PuestoIndicadorScreen,
    "PLAdministrationScreen":PLAdministrationScreen,
    "MeetingManagementScreen":MeetingManagementScreen,
    "SurveyManagementScreen": SurveyManagementScreen,
    "ToolsPartsMappingScreen": ToolsPartsMappingScreen,
    "PLByBucketScreen":PLByBucketScreen,
    "SurveyAnalyzeScreen" : SurveyAnalyzeScreen,
    "TableauDashboardScreen": TableauDashboardScreen,
    "OrganizationalScoreSceen" : OrganizationalScoreSceen,
    "SalesPerformanceDashboardScreen": SalesPerformanceDashboardScreen,
    "MonthlyFollowUpDashboardScreen": MonthlyFollowUpDashboardScreen,
    "YTDProgressDashboardScreen": YTDProgressDashboardScreen,
    "SalesPerformanceSalesRepManagementScreen": SalesPerformanceSalesRepManagementScreen,
    "IncentiveProgramSetUpScreen": IncentiveProgramSetUpScreen,
    "IncentiveProgramsGoalsScreen" : IncentiveProgramsGoalsScreen,
    "PODScreen": PODScreen,
    "FolderScreen":FolderScreen,
    "LibraryScreen":LibraryScreen,
    "FileLibraryScreen":FileLibraryScreen,
    "FileLibraryByUserScreen":FileLibraryByUserScreen,
    "IndividualIncetiveCompensationScreen":IndividualIncetiveCompensationScreen,
    "DownTimeScreen":DownTimeScreen,
    "VisitLogScreen":VisitLogScreen,
    "VisitLogMovilScreen":VisitLogMovilScreen,
    "VisitLog":VisitLog,
    "CheckInScreen":CheckInScreen,
    "CellHomeMenuScreen" : CellHomeMenuScreen,
    "DailyOperationCheckListScreen" : DailyOperationCheckListScreen,
    "ScrapScreen":ScrapScreen,
    "CheckOutScreen":CheckOutScreen,
    "ChangeOverScreen":ChangeOverScreen,
    "ExecutiveOrderTrendScreen": ExecutiveOrderTrendScreen,
    "DisplayOperationsDashboards": DisplayOperationsDashboards,
    "PlanningScreen" : PlanningScreen,
    "DashboardUtilizationScreen" : DashboardUtilizationScreen,
    "DashboardScrapScreen" : DashboardScrapScreen,
    "BMTrackingDashboardsScreen" : BMTrackingDashboardsScreen,
    "TalentPlanningScreen" : TalentPlanningScreen,
    "BusinessCard": BusinessCard,
    "ManageBusinessCardsScreen": ManageBusinessCardsScreen,
    "OrangePageScreen" : OrangePageScreen,
    "SalesDashboardScreen":SalesDashboardScreen,
    "WireSteelGeneralViewScreen": WireSteelGeneralViewScreen,
    "MMADashboardScreen": MMADashboardScreen,
    "DigitalViewDashboardScreen": DigitalViewDashboardScreen,
    "ITAssetsScreen": ITAssetsScreen,
    "MTDPerformanceReportScreen": MTDPerformanceReportScreen,
    "UsageTrackingScreen": UsageTrackingScreen,
    "BacklogByDivision": BacklogByDivisionScreen,
    "PriceTrackerScreen": PriceTrackerScreen,
    "IncentiveProgramManualAdjustmentsScreen": IncentiveProgramManualAdjustmentsScreen,
    "SalesDataPivotScreen" : SalesDataPivotScreen,
    "ProductionDailyFollowUPScreen": ProductionDailyFollowUPScreen,
    "VolumeArchitectureOverviewScreen": VolumeArchitectureOverviewScreen,
    "ProductionWeeklyFollowUPScreen": ProductionWeeklyFollowUPScreen,
    "DAISeerScreen": DAISeerScreen,
    "SteelTrackMonitorScreen": SteelTrackMonitorScreen,
    "CompanyOverviewScreen": CompanyOverviewScreen,
    "IndividualDevelopmentPlanScreen": IndividualDevelopmentPlanScreen,
    "BusinessEnablingServices2024" : BusinessEnablingServices2024,
    "ProfitCubeScreen": ProfitCubeScreen,
    "ConstructConnectScreen" : ConstructConnectScreen,
    "GantryBoxLookupScreen": GantryBoxLookupScreen,
    "CustomerServiceScreen": CustomerServiceScreen,
    "DailyOperationTrackerScreen":DailyOperationTrackerScreen,
    "MCSWNewsletterScreen": MCSWNewsletterScreen,
    "DnTNewsletterScreen": DnTNewsletterScreen,
    "InventoryFollowUpScreen" : InventoryFollowUpScreen,
    "WeeklySalesScreen" : WeeklySalesScreen,
    "MonthlySalesScreen" : MonthlySalesScreen,
    "ManageADPEmployeesScreen": ManageADPEmployeesScreen,
    "SalesRepFollowUpScreen": SalesRepFollowUpScreen,
    "SalesByLocationScreen": SalesByLocationScreen,
    "ViewEmployeeITAssetsScreen": ViewEmployeeItAssetsScreen,
    "DailyProductionSummaryScreen" : DailyProductionSummaryScreen,
    "DailyDowntimeSummaryScreen" : DailyDowntimeSummaryScreen,
    "MonthlyProductionSummaryScreen" : MonthlyProductionSummaryScreen,
    "InventoryWeeklyFollowUpScreen" : InventoryWeeklyFollowUpScreen,
    "MonthlyDowntimeSummaryScreen" : MonthlyDowntimeSummaryScreen,
    "ProductionMonthlyFollowUPScreen": ProductionMonthlyFollowUPScreen,
    "InventoryBySGDashScreen" : InventoryBySGDashScreen,
    "ARAgingDashScreen" : ARAgingDashScreen,
    "APAgingDashScreen" : APAgingDashScreen,
    "UploadResultsCompensationScreen":UploadResultsCompensationScreen
};


export default Components;

/*
const routes = [ 
    {
        collapse: true,
        path: '/Questionarios',
        name: 'Questionaries',
        icon: 'ni ni-collection', 
        layout: '/layout',
        state: "FormularioCollapse",
        views: [ {
            path: "/Formularios",		 
            name: "Start Questionnaire",		 
            icon: " ",	
            miniName: "SQ", 
            component: Formularios,	 
            layout: "/layout",			 
        },
        {
            path: "/ResultadoFormularios",		 
            name: "Questionnaire Results",		 
            icon: " ",	
            miniName: "SQ", 
            component: ResultadoFormulario,	 
            layout: "/layout",			 
        }]
    },  
    {
        path: '/PocketMargin',
        name: 'Profitability Summary Report',
        icon: 'ni ni-chart-bar-32',
        component: PocketMarginResults,
        redirect: false,
        layout: '/layout',
        views: []
    },
    {
        path: '/ResponderFormulario',
        name: 'Start Questionnaire 2',
        icon: 'ni ni-shop text-primary',
        component: ResponderFormulario,
        redirect: true,
        layout: '/layout',
    },
    {
        path: '/FormularioResuelto',
        name: 'Questionnaire Resolved Det',
        icon: 'ni ni-shop text-primary',
        component: ResultadoFormularioDet,
        redirect: true,
        layout: '/layout',
    }  
];


export default routes;
*/