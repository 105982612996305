/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect,useRef  } from 'react'; 
import { useTranslation } from 'react-i18next';  
import { Row, Col } from 'reactstrap';  
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';  
import HomeMenuCard from '../../../components/Cards/HomeMenuCard/HomeMenuCard.jsx';
import CareersMidContinentMenuImg from '../../../assets/img/MCSW-CareerImage.png';
import ListItem from '../../../components/ListGroup/ListItem.jsx'     
import PDFViewer from '../../../components/Controls/PDFViewer/PDFViewer.jsx'; 
import { Link } from 'react-router-dom';
import FieldTag from 'components/Controls/FieldTag.jsx';
import { Tooltip } from 'primereact/tooltip'; 
import VideoPlayer from 'components/Controls/VideoPlayer/VideoPlayer';
import { Dialog } from 'primereact/dialog';

const CareersMidContinent = (props) => { 
 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  const [LibraryFileByUserList, setLibraryFileByUserList] = useState([]);  
  const [showVisorPDF, setShowVisorPDF] = useState(false);
  const [file, setFile] = useState(null);  
  const [EsShowDialog, setEsShowDialog] = useState(false);
  const [fileExtension, setfileExtension] = useState('');
  const hrLibraryLink  = useRef(null); 

  useEffect(() => { 
    loadFileLibraryByUserList(
      {
        ClaLibrary: 8,  /* ESTA LIBRERIAS SON MANTENIDAS POR DESARROLLO LA PRIMERA QUE CREAMOS FUE LA DE HR */ 
        AttributeList: []
      });  
  }, []);
 
 
  const loadFileLibraryByUserList = (filtersData) => {
    const GetFileLibraryByUserList = `${config.UrlApiProject}DMS/GetFileLibraryByUserList`;
    console.log(`filtersData:${filtersData}`);
    const paramsToService = {
      ClaLibrary:filtersData.ClaLibrary,
      LibraryAttributeListDTO: filtersData.AttributeList,
      ShowInHome: true
    };

    callApi(GetFileLibraryByUserList, 'POST', paramsToService, (response) => {
      setLibraryFileByUserList(response.data.LibraryFileByUser);      
    });
  };
  
  const listItemElement = (item, index) => {

    const element = (
      <>  
        <div className='relative'> 
          <span className="spanunderline Cursor" onClick={() => viewFile(item)}>{item.TitleFile}</span>&nbsp;
          <Tooltip target="#infoFile" />
          
          {
            item.DescriptionFile !== null && item.DescriptionFile.length > 0
            ?
            (
              <i
                className="fas fa-info-circle new-option-choice info-color"
                id="infoFile"  
                data-pr-tooltip={item.DescriptionFile}
                tooltip={item.DescriptionFile}
              >
              </i>
          )
            :
            (<></>)
          }
          &nbsp; 
          {
            item.IsNewFile ?
            (
              <FieldTag 
                label="New"
                className="small-tag light-green"
              />
            )
            :
            (<></>)
          }
          &nbsp;  
          {
            item["External Link URL"] !== null && item["External Link URL"] !== undefined ? (
              <a className='Cursor underline' href={item["External Link URL"]} target="_blank" rel="noopener noreferrer">
                <small title={item["External Link URL"]}><i className="fas fa-external-link-alt new-option-choice link-color"></i> 
                </small>
              </a>
            ) : (<></>)
          }
        </div> 
    
      </>
    )
 

    return ( 
      <ListItem
        key={index}
        title={element}
        color="#BE8463"
        icon={(<><i className="pi pi-minus" style={{color: "#BE8463"}}></i>&nbsp;</>)}
      >
      </ListItem>  
    );
  }

   const renderHeader = () => {
      return (
        <div>
          <Row className="text-center">
            <Col lg={12} md={12} sm={12} xs={12} className="text-left">
              {t('dms:Viewer')}
            </Col>
          </Row>
        </div>
      );
    };
  
  const viewFile = (rowData) => { 
    const GetFileData = `${config.UrlApiProject}DMS/GetLibraryFileById`;  
    // const GetFileData = `${config.UrlApiProject}DMS/GetFileFromServer`;  
    const paramsToService = { 
      IdLibraryFile:rowData.IdLibraryFile
    };   
    callApi(GetFileData, 'POST', paramsToService, (response) => {
      const AnexoDetalle = response.data.AnexoDetalle[0];
      const AnexoImg = response.data.ImgDownLoad;
        console.log('viewFile',response);
      if(AnexoDetalle!==undefined){
        
        let linkSource = ''

        switch (AnexoDetalle.TypeExtension) {
          case 'mp4':
          case 'webm':
            linkSource = `data:video/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
            setEsShowDialog(true); 
            break;
          case 'pdf':
              linkSource = `data:application/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
              setShowVisorPDF(true); 
              break;
          case 'jpg':
          case 'gif':
          case 'jpeg':
          case 'jfif':
          case 'pjpeg':
          case 'png':
          case 'svg':
          case 'webp':
          case 'bmp':
          case 'ico':
          case 'cur':
                linkSource = `data:image/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
                setEsShowDialog(true); 
                break;
          default:
            break;
        } 
        // SI NO FORMAMOS EL LINK QUE LO INTENTE DESCARGAR
        if (linkSource === ''){
          const linkSource = `data:application/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
          const downloadLink = document.createElement("a");
          const fileName = `${AnexoDetalle.NomLibraryFile}`;
          downloadLink.href = linkSource;
          downloadLink.download = `${fileName}.${AnexoDetalle.TypeExtension}`;
          downloadLink.click();
        }
        else{  
        setFile(linkSource);
        setfileExtension(AnexoDetalle.TypeExtension);
        }

      }
    });  
  }

    return (
      <> 
        <HomeMenuCard 
          className='HomeMenuCard' 
          title={t('hr:CareersMidContinent')} 
          imageSrc={CareersMidContinentMenuImg} 
          color="#BE8463"
          isLarge={true}
        >
          <Row className='align-items-start p-fluid'>
            <Col lg={12} md={12} sm={12} xs={12}>   
              <Row>
                <Col>
                  <div><b>How to Apply:</b></div> 
                  <div><span>1. Apply directly through Indeed by clicking on the blue box with an arrow icon below next to each job profile.</span></div>
                  <div><span>2. Send an inquiry email to bmigliori@mcswusa.com</span></div>
                </Col>
              </Row>
              <Row className='pt-4'>
                {
                LibraryFileByUserList.length > 0 ? (
                  LibraryFileByUserList.map((item, index) => {
                    return (
                      <Col lg={4} md={4} sm={12} xs={12} key={index}>
                        {listItemElement(item, index)}
                      </Col>
                    );
                  })
                ) : (
                  <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                    <div className='pt-2'>
                      <i className="fa fa-solid fa-briefcase" style={{ fontSize: '36px'}}></i>
                      <p><h2>{t('hr:CareersMidContinentNotJobs')}</h2></p>
                    </div>
                  </Col>
                )
              }
              </Row>
            </Col> 
          </Row>

        </HomeMenuCard>
        <PDFViewer
          file={file} 
          show={showVisorPDF}
          onHide={() => { setShowVisorPDF(false) }}
        > 
        </PDFViewer> 
        <Dialog
          visible={EsShowDialog}
          modal
          style={{ width: '100vw' }}
          footer=""
          header={renderHeader} 
          closable={true}
          className="dialog-header-background-blue"
          maximized={true}
          draggable={false}
          blockScroll={true}
          onHide={() => setEsShowDialog(false)}
        >
        
          {
                      fileExtension === 'mp4'
                      ||
                      fileExtension === 'webm'
                      ?
                      (
                        <Row className='h-100'>
                          <Col className='h-100 video-visor'>  
                            <VideoPlayer reference={VideoPlayerViewer} url={file} height="100%"></VideoPlayer>  
                          </Col>
                        </Row>
                      )
                      :
                      (<></>)
                    }
          {
                      fileExtension === 'jpg'   || fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'jfif' ||
                      fileExtension === 'pjpeg' || fileExtension === 'png' || fileExtension === 'svg' || fileExtension === 'webp'  ||
                      fileExtension === 'bmp'   || fileExtension === 'ico' || fileExtension === 'cur' 
                      ?
                      (
                        <Row className='h-100'>
                          <Col className='h-100'>   
                            <img src={file} width="100%" alt="" />
                          </Col>
                        </Row>
                      )
                      :
                      (<></>)
                    }  
        </Dialog>
        <Link
          ref={hrLibraryLink}
          to={{   
                      pathname: "FileLibraryByUserScreen", 
                      search: `?ClaLibrary=8` 
                      }}
        >
        </Link> 
      </>  
        ); 
};

export default CareersMidContinent;