/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';
import PropTypes from 'prop-types';
import "./HomeMenuCard.scss";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardTitle,
    CardText,
    ListGroupItem,
    ListGroup,
    Row,
    Col
  } from "reactstrap";
  

function HomeMenuCard ({
     color
    ,title
    ,imageSrc
    ,children
    ,className
    ,isImgHeader
    ,isLarge
  }) { 
    return (  
      <>  
        <Card> 
          <CardBody className={className}>
            {
                      isImgHeader ? (
                        <>
                          <div className='borderbottomcard'> 
                            <Row>
                              {imageSrc !== undefined ? ( 
                                <Col lg={4} md={4} sm={6} xs={12}>  
                                  <div
                                    className="image-menu-card-Other" 
                                    style={{borderRightColor:color}}
                                  >
                                    <img alt="..." className="image-left-card-Other" src={imageSrc}></img>
                                  </div>
                                </Col>
             )
              : <></>}
                              <Col lg={8} md={8} sm={6} xs={12} className="h-100"> 
                                <div className="h-100 items-menu-card">
                                  <div className="pt-4 pl-4 title-menu-card text-center">
                                    <span style={{color}}>{title}</span>
                                  </div> 
                                </div> 
                              </Col> 
                            </Row>
                          </div>
                          <Row>
                            <Col>
                              <div className="pt-4 pl-4 title-menu-card">
                                {children}
                              </div>  
                            </Col>
                          </Row>
                        </>
                    ):(
                      <>
                        <Row className="align-items-start h-100">
                          {imageSrc !== undefined ? ( 
                            <Col lg={isLarge?2:4} md={isLarge?2:4} sm={6} xs={12} className="h-100">  
                              <div
                                className="image-menu-card" 
                                style={{borderRightColor:color}}
                              >
                                <img alt="..." className="image-left-card" src={imageSrc}></img>
                              </div>
                            </Col>
             )
              : <></>}
                          <Col lg={isLarge?10:8} md={isLarge?10:8} sm={6} xs={12} className="h-100"> 
                            <div className="h-100 items-menu-card">
                              <div className={`pt-4 pl-4 title-menu-card ${isLarge ? 'pb-0' : 'pb-0'}`}>
                                <span style={{color}}>{title}</span>
                              </div> 
                              <div className="pt-4 pl-4"> 
                                {children}
                              </div>  
                            </div> 
                          </Col> 
                        </Row>
                      </>
                    )
                  }
          </CardBody>
        </Card>
      </> 
    )
}   

HomeMenuCard.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    imageSrc: PropTypes.string,
    className: PropTypes.string,
    isImgHeader:  PropTypes.bool,
    isLarge:  PropTypes.bool
}

export default HomeMenuCard; 
