/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect,useRef  } from 'react'; 
import { useTranslation } from 'react-i18next';  
import { Row, Col , 
    ListGroupItem,
    ListGroup,
    Progress,} from 'reactstrap';  
import HomeMenuCard from '../../../components/Cards/HomeMenuCard/HomeMenuCard.jsx';
import WeAreMCSWMenuImg from '../../../assets/img/MCSW-welder.png';
import ListItem from '../../../components/ListGroup/ListItem.jsx'  
import pdfMission from '../../../assets/pdf/MCSW_Mission.pdf';   
import pdfVision from '../../../assets/pdf/MCSW_Vision.pdf';   
import pdfGuidingPrincipleAndcoreValues from '../../../assets/pdf/2026_Values_and_Competencies.pdf';   
import PDFViewer from '../../../components/Controls/PDFViewer/PDFViewer';
import pdfCodeofEthics from '../../../assets/pdf/Code_of_Ethics_2023.pdf';   
import { Link } from 'react-router-dom';

const WeAreMCSWMenu = (props) => { 
 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
   
  const [file, setFile] = useState(null);
  const [showVisorPDF, setShowVisorPDF] = useState(false);
  
  const onClickMission = () => {
    setFile(pdfMission);
    setShowVisorPDF(true);
  }

  const onClickVision = () => {
    setFile(pdfVision);
    setShowVisorPDF(true);
  }

  const onGuidingPrincipleAndcoreValue = () => {
    setFile(pdfGuidingPrincipleAndcoreValues);
    setShowVisorPDF(true);
  }

  const onClickCodeofEthics = () => {
    setFile(pdfCodeofEthics);
    setShowVisorPDF(true);
  }
  const TIPSPWLnk = useRef(null);

  const onClickTIPSPW = () => {
    TIPSPWLnk.current.click();
  }
  
  const WorkingAdvantageBenefitLnk = useRef(null);

  const onClickWorkingAdvantageBenefit = () => {
    WorkingAdvantageBenefitLnk.current.click();
  }
  
    return (
      <> 
        <HomeMenuCard className='HomeMenuCard' title={t('hr:WeAreMCSW')} imageSrc={WeAreMCSWMenuImg} color="#d0323a"> 
          <ListItem title={t('hr:Mission')} color="#d0323a" onClick={onClickMission}></ListItem> 
          <ListItem title={t('hr:Vision')} color="#d0323a" onClick={onClickVision}></ListItem>
          <ListItem title={t('hr:GuidingPrinciplesAndCoreValues')} color="#d0323a" onClick={onGuidingPrincipleAndcoreValue}></ListItem> 
          <ListItem title={t('hr:CodeofEthics')} color="#d0323a" onClick={onClickCodeofEthics}></ListItem>
          <ListItem title={t('hr:TIPSPW')} color="#d0323a" onClick={onClickTIPSPW}></ListItem>
          <ListItem title={t('hr:WorkingAdvantageBenefit')} color="#d0323a" onClick={onClickWorkingAdvantageBenefit}></ListItem>
         
        </HomeMenuCard>
        <PDFViewer
          file={file} 
          show={showVisorPDF}
          onHide={() => { setShowVisorPDF(false) }}
        > 
        </PDFViewer> 
        <Link ref={TIPSPWLnk} to={{ pathname: "https://www.tips-program.com/TipsUSA/View/Home/" }} target="_blank"></Link> 
        <Link ref={WorkingAdvantageBenefitLnk} to={{ pathname: "https://www.workingadvantage.com/" }} target="_blank"></Link> 
      </>  
        ); 
};

export default WeAreMCSWMenu;
