/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { config } from '../../../utils/config.js';
import { callApi, showSweetAlert, getSessionItem } from '../../../utils/utils.js'; 
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator.js';
import moment from 'moment'; 
import swal from '@sweetalert/with-react'; 
import PageContent from 'layouts/PageContent.jsx'; 
import UploadResultsCompensation from './UploadResultsCompensation.jsx'; 
import FieldUpload from 'components/Controls/FieldUpload/FieldUpload.jsx'; 
import urlFileXls from '../../../assets/layout/Layout_Compensation_Incentive_Program.xlsx'; 
import { Dialog } from 'primereact/dialog';

const UploadResultsCompensationScreen = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['uploadResultsCompensation', 'common']);
  const [errors, setErrors] = useState(null); 
  const [showUploadResults, setShowUploadResults] = useState(false);
  const [file, setFile] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(0);

  useEffect(() => { 
  }, []);


  const onUploadClick = async () => { 
    const validationSchema = yup.object().shape({
      file: yup.array().min(1, t('common:RequiredField')).required(t('common:RequiredField')).nullable()
    })
    const value = {
      file
    }
    const validator = new YupValidator(validationSchema);
    const isValid = await validator.validate(value);
    if (!isValid) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }
    console.log('file file',file)
    const urlWebService = `${config.UrlApiProject}SalesPerformanceSales/UploadResultsFromSpreadsheet`;
    const paramsService = {
      FileData: file[0].FileData,
      FileName: file[0].FileName,
      FileExtension: file[0].FileExtension
    }

    callApi(urlWebService, 'POST', paramsService, (response) => {
      setFile([]); 
      setShowUploadResults(false); 
      setForceRefresh(forceRefresh+1);
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
    });
  } 
   
  const downloadFileCompensation = () => {
    const link = document.createElement('a');
    link.href = urlFileXls;
    link.download = t('uploadResultsCompensation:LayoutCompensationIncentiveProgram');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  const uploadHeader = () => {
    return (
      <>
        {t('uploadResultsCompensation:UploadResults')}
      </>
    )
  }

  const uploadFooter = () => {
    return (
      <>
        <FieldButton label='Submit' onClick={() => onUploadClick()} icon='pi pi-upload' className='p-button-success' />
      </>
    )
  }

  return (
    <>
      <PageContent title={t('uploadResultsCompensation:UploadResultsCompensation')}>
        <Row className="p-3">
          <Col lg={12} md={12} sm={12} xs={12} className="p-d-flex p-jc-end p-ai-center">
            <div className="p-d-flex">
              <FieldButton
                className="p-button p-button-secondary p-mr-2"
                onClick={downloadFileCompensation}
              >
                <i className="pi pi-download"></i>&nbsp;{t('uploadResultsCompensation:DownloadLayout')}
              </FieldButton>
              <FieldButton
                className="p-button p-button-warning"
                onClick={() => setShowUploadResults(true)}
              >
                <i className="pi pi-upload"></i>&nbsp;{t('uploadResultsCompensation:UploadResults')}
              </FieldButton>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <UploadResultsCompensation forceRefresh={forceRefresh}>
            </UploadResultsCompensation>
          </Col>
        </Row>      
        <Dialog
          visible={showUploadResults}
          onHide={() => {
                  setShowUploadResults(false);
                  setFile([]);
                  setErrors(null);
                }}
          header={uploadHeader}
          footer={uploadFooter}
          blockScroll={true}
          style={{ width: '30vw' }}
          breakpoints={{'1400px': '25vw', '1000px': '35vw', '510px': '50vw', '560px': '100vw' }}
        >
          <FieldUpload
            name='file'
            files={file} 
            onChange={(files) => { 
              setFile(files); 
              setErrors(null); 
          }}
            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            errors={errors}
            uploadPrompt={t('uploadResultsCompensation:UploadPrompt')}
          >
          </FieldUpload>
        </Dialog>
      </PageContent>
    </>
  );
};
export default UploadResultsCompensationScreen;
