import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";
import 'moment/locale/es';    

import commonEN from "./locales/en/Common/common.json";
import formularioEN from "./locales/en/Formulario/formulario.json";
import productionDashboardEN from "./locales/en/ProductionDashboards/productionDashboard.json";
import hrEN from "./locales/en/HR/hr.json";
import kpisManagementEN from "./locales/en/ScoreCard/KpisManagement.json";
import caratulaproyectoEN from "./locales/en/ScoreCard/CaratulaProyecto.json";
import scoreCardEN from "./locales/en/ScoreCard/scoreCard.json";
import MyActivitiesEN from "./locales/en/ScoreCard/MyActivities.json";
import minutaproyectoEN from "./locales/en/ScoreCard/MinutaProyecto.json";
import minutameetingEN from "./locales/en/ScoreCard/MinutaMeeting.json";
import proyectoInvolucradosEN from "./locales/en/ScoreCard/ProyectoInvolucrados.json";
import proyectoProgressReportEN from "./locales/en/ScoreCard/ProyectoProgressReport.json";
import orangePageEN from "./locales/en/ScoreCard/OragePage.json";
import positionEN from "./locales/en/ScoreCard/Position.json";
import misActividadesEN from "./locales/en/ScoreCard/MisActividades.json";
import fasesProyectosEN from "./locales/en/ScoreCard/FasesProyectos.json";
import operationsEN from "./locales/en/Operations/operations.json";
import reporteAvanceMensualProyectoEN from "./locales/en/ScoreCard/ReporteAvanceMensualProyecto.json";
import misProyectosScreenEN from "./locales/en/ScoreCard/MisProyectosScreen.json";
import proyectoMaestroDetalleEN from "./locales/en/ScoreCard/ProyectoMaestroDetalle.json";
import actionPlanEN from "./locales/en/ScoreCard/ActionPlan.json";
import itEN from "./locales/en/IT/it.json";
import usageTrackingEN from "./locales/en/IT/usageTracking.json";
import corporateEN from "./locales/en/ScoreCard/Corporate.json";
import focusReportEN from "./locales/en/ScoreCard/FocusReport.json";
import puestoIndicadorEN from "./locales/en/ScoreCard/PuestoIndicador.json";
import surveyEN from "./locales/en/HR/Survey.json";
import organizationalChartEN from "./locales/en/ScoreCard/OrganizationalChart.json";
import salesPerformanceEN from "./locales/en/SalesPerformance/salesPerformance.json"
import salesPerformanceSalesEN from "./locales/en/SalesPerformance/SalesPerformanceSales.json";
import podEn from "./locales/en/POD/pod.json";
import dmsEN from "./locales/en/DMS/dms.json";
import individualIncetiveCompensationEN from "./locales/en/SalesPerformance/IndividualIncetiveCompensation.json";
import downTimeEn from "./locales/en/DownTime/DownTime.json";
import visitLogEN from "./locales/en/HR/VisitLog.json";
import tabletsEN from "./locales/en/Operations/Tablets.json";
import mapEN from "./locales/en/Operations/map.json";
import qrEN from "./locales/en/QRCode/QRCode.json";
import salesDashboardEN from "./locales/en/SalesPerformance/salesDashboard.json";
import dgtEN from "./locales/en/DGT/dgt.json";
import mtdEN from "./locales/en/MTD/mtd.json";
import priceTrackerEN from "./locales/en/SalesPerformance/priceTracker.json";
import incentiveProgramManualAdjustmentsEN from "./locales/en/SalesPerformance/incentiveProgramManualAdjustments.json";
import wtwEN from "./locales/en/WTW/wtw.json";
import daiSeerEN from "./locales/en/DAISeer/DAISeer.json";
import companyOverviewEN from "./locales/en/Operations/companyOverview.json";
import iDPV2EN from "./locales/en/HR/IDPV2.json";
import MCSWNewsletterEN from "./locales/en/HR/MCSWNewsletter.json"
import uploadResultsCompensationEN from "./locales/en/SalesPerformance/uploadResultsCompensation.json";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
 en: {
    // Namspaces
    common: commonEN,
    formulario: formularioEN,
    productionDashboard: productionDashboardEN,
    hr: hrEN,
    kpisManagement: kpisManagementEN,
    caratulaproyecto: caratulaproyectoEN,
    scoreCard: scoreCardEN,
    myactivities: MyActivitiesEN,
    minutaproyecto: minutaproyectoEN,
    minutameeting: minutameetingEN,
    proyectoinvolucrados: proyectoInvolucradosEN,
    proyectoprogressreport: proyectoProgressReportEN,
    orangePage: orangePageEN,
    position: positionEN,
    misActividades: misActividadesEN,
    fasesproyectos: fasesProyectosEN,
    operations: operationsEN,
    reportevanceMensualProyecto: reporteAvanceMensualProyectoEN,
    misProyectosScreen: misProyectosScreenEN,
    proyectoMaestroDetalle: proyectoMaestroDetalleEN,
    actionPlan: actionPlanEN,
    it: itEN,
    usageTracking: usageTrackingEN,
    corporate: corporateEN,
    focusReport: focusReportEN,
    puestoIndicador: puestoIndicadorEN ,
    survey: surveyEN,
    organizationalChart: organizationalChartEN,
    salesPerformance: salesPerformanceEN,
    salesPerformanceSales: salesPerformanceSalesEN,
    pod: podEn,
    dms: dmsEN,
    indIncCompensation: individualIncetiveCompensationEN,
    downTime: downTimeEn,
    visitLog:visitLogEN,
    tablets: tabletsEN,
    map: mapEN,
    qr: qrEN,
    salesDashboard: salesDashboardEN,
    dgt: dgtEN,
    mtd: mtdEN,
    priceTracker: priceTrackerEN,
    incentiveProgramManualAdjustments: incentiveProgramManualAdjustmentsEN,
    wtw: wtwEN,
    daiSeer: daiSeerEN,
    companyOverview: companyOverviewEN,
    iDPV2: iDPV2EN,
    MCSWNewsletter: MCSWNewsletterEN,
    uploadResultsCompensation: uploadResultsCompensationEN
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  moment.locale('en'); // can pass in 'en', 'fr', or 'es'


  export default i18n;