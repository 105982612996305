/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col } from 'reactstrap';
import { config } from '../../../utils/config.js';
import { callApi } from '../../../utils/utils.js';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator.js';
import moment from 'moment';
import { Skeleton } from 'primereact/skeleton';

const UploadResultsCompensation = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['uploadResultsCompensation', 'common']);
  const [errors, setErrors] = useState({});  
  const [UploadResultsList , setUploadResultsList ] = useState([]);
  const [EsCargandoListado, setEsCargandoListado] = useState(false); 

  useEffect(() => {
    obtieneUploadResultComensation();
  }, [props.forceRefresh]);

  const obtieneUploadResultComensation = () => { 
    setEsCargandoListado(true);
    const getIndividualIncetiveCompensation = `${config.UrlApiProject}SalesPerformanceSales/GetUploadResultsCompensation`;
    const filters = {}
    callApi(getIndividualIncetiveCompensation, 'POST', filters, (response) => { 
      setUploadResultsList(response.data.UploadResultsCompensation);
      setEsCargandoListado(false);
    });
  };

  const skeletonTemplate = () =>{
    return (<Skeleton height="1rem" width="100%" className="mb-2"></Skeleton>);
  }  

  const downloadFile = (row) => {  
      const linkSource = `data:application/${row.ExtensionArchivo};base64,${row.Archivo}`
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `${row.NombreArchivo}`;
      downloadLink.click();
  }

  const downloadColumn = (row) => {
    return (
      <>
        <FieldButton
          className='p-button-rounded p-button-warning' 
          icon='pi pi-download'
          tooltip={t('uploadResultsCompensation:Download')} 
          onClick={() => downloadFile(row)}
        />
      </>
    )
  }

  const FechaCargaTemplate = (rowData) => {
      return (
        <>
          {rowData.FechaCarga !== null ? moment(rowData.FechaCarga).format('MMMM DD, YYYY h:mm:ss a') : null}
        </>
      );
  };

  const ListadoUploadResults = () => {
      if (EsCargandoListado){
        return (
          <> 
            <FieldDataTable 
              value={[{},{},{},{}]}
              paginator={false} 
            >     
              <Column header={t('uploadResultsCompensation:Year')} className='text-center' headerStyle={{ width: '50px' }} body={skeletonTemplate}></Column>
              <Column header={t('uploadResultsCompensation:FileName')} className='text-left' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column>
              <Column header={t('uploadResultsCompensation:UserName')} className='text-left' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
              <Column header={t('uploadResultsCompensation:ProcessDate')} className='text-left' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
              <Column header={t('uploadResultsCompensation:DownloadFile')} className='text-center' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column> 
            </FieldDataTable>
          </>  
        )
      }
      return (
        <FieldDataTable 
          value={UploadResultsList}    
          paginator={false} 
          className="p-datatable-striped"
        >    
          <Column field="Anio" columnKey="Anio" header={t('uploadResultsCompensation:Year')} className='text-center' headerStyle={{ width: '50px' }}></Column>
          <Column field="NombreArchivo" columnKey="NombreArchivo" header={t('uploadResultsCompensation:FileName')} className='text-left' headerStyle={{ width: '120px' }}></Column>
          <Column field="UserName" columnKey="UserName" header={t('uploadResultsCompensation:UserName')} className='text-left' headerStyle={{ width: '120px' }}></Column>
          <Column body={FechaCargaTemplate} field="FechaCarga" columnKey="FechaCarga" header={t('uploadResultsCompensation:ProcessDate')} className='text-left' headerStyle={{ width: '120px' }}></Column>
          <Column body={downloadColumn} header={t('uploadResultsCompensation:DownloadFile')} className='text-center' headerStyle={{ width: '50px', textAlign: 'center' }}></Column>
        </FieldDataTable>
      )
  }
       
  return (
    <> 
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}> 
          {ListadoUploadResults()} 
        </Col>  
      </Row>
    </>
  );
};
export default UploadResultsCompensation;
